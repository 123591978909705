import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Parser, { domToReact } from 'html-react-parser';

class MentionsPage extends Component {
  render() {
    const data = this.props.data

    return (
      <>
        <SEO title="Anarchy in the kitchen - Mentions légales" description="Anarchy in the kitchen - Mentions légales"/>
        <div className="maincontent anarchycontent">
        {Parser(data.wordpressPage.content, {
                replace: function(domNode) {
                  if (domNode.name === 'h1') {
                    return (
                      <h2 data-text-shadow={domNode.children[0].data} data-aos="fade">
                        {domToReact(domNode.children)}
                      </h2>)
                  }
                  if (domNode.name === 'h2') {
                    return (
                      <h3 data-text-shadow={domNode.children[0].data} data-aos="fade">
                        {domToReact(domNode.children)}
                      </h3>)
                  }
                  if (domNode.name === 'h3') {
                    return (
                      <h4 data-text-shadow={domNode.children[0].data} data-aos="fade">
                        {domToReact(domNode.children)}
                      </h4>)
                  }
                  if (domNode.name === 'hr') {
                    return (
                      <hr className="separator-vague wp-block-separator is-style-wide " data-aos="fade-up"/>
                    )
                  }
                  
                  if (domNode.attribs && domNode.attribs.class !== undefined && domNode.attribs.class.toLowerCase() === 'gauche') {
                    return (
                    <div className="paragraphe" data-aos="fade-left">
                      {domToReact(domNode.children)}
                    </div>
                    )
                  }
                  if (domNode.attribs && domNode.attribs.class !== undefined && domNode.attribs.class.toLowerCase() === 'droite') {
                    return (
                    <div className="paragraphe" data-aos="fade-right">
                      {domToReact(domNode.children)}
                    </div>
                    )
                  }
                  if (domNode.attribs && domNode.attribs.class !== undefined && domNode.attribs.class.toLowerCase() === 'bas') {
                    return (
                    <div className="paragraphe" data-aos="fade-up">
                      {domToReact(domNode.children)}
                    </div>
                    
                    )
                  }
                  if (domNode.attribs && domNode.attribs.class !== undefined && domNode.attribs.class.toLowerCase() === 'haut') {
                    return (
                    <div className="paragraphe" data-aos="fade-down">
                      {domToReact(domNode.children)}
                    </div>
                    )
                  }

                  if (domNode.name === 'p') {
                    return (
                      <p data-aos="fade">
                        {domToReact(domNode.children)}
                      </p>
                    )
                  }

                  if (domNode.name === 'div') {
                    return (
                      <p data-aos="fade">
                        {domToReact(domNode.children)}
                      </p>
                    )
                  }
                  
                }
              })}
          </div>
      </>
    )
  }
}

export default MentionsPage


export const pageQuery = graphql`
  query {
    wordpressPage(title: {eq: "Mentions"}) {
      id
      title
      content
    }
  }
`

